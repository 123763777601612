import React from 'react'
import { navigate } from "gatsby";
import SEO from '../components/SEO'



const impressum = () => {
  return (
    <>
    <SEO title="Impressum" description="Impressum"/>
    <div style={{maxWidth:'900px', margin:'4vw auto', padding:'var(--gutter)'}}>
      <div style={{textAlign:'right'}}>
        <button onClick={() => navigate("/")}>
        Go Back
      </button>
      </div>
      <h1>Impressum</h1>
      <p>Verantwortlich für die Inhalte:</p>
      <ul className={'unstyledList'}>
        <li>Susanna Praetorius</li>
        <li>Lucy-Lameck-Str. 43</li>
        <li>12049 Berlin</li>
        <li>0049 179 355 55 42</li>
        <li><a href="mailto:s.praetor@gmx.de">s.praetor@gmx.de</a></li>
      </ul>
      <p>Umsatzsteuerbefreiung gemäß § 4 Nr. 14 Buchst. a UStG</p>
      
    <p>
    <strong>Homepage: </strong>Matthias Campe<br />
    <strong>Logo: </strong>Hannah Whitlow
    </p>

    <h2>Datenschutz</h2>
    <p>
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
    </p>
    
    </div>
    </>
  )
}

export default impressum
